<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import CryptoJS from "crypto-js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {
        role: "etudiant",
      },
      teachers: [],
      classTeacher: null,
      students: [],
      studentsList: [],
      allStudents: [],
      allStudentsBackup: [],
      fetchedStudents: [],
      selectedStudents: [],
      rightSearch: "",
      student: {},
      page: 1,
      perPage: 5,
      pages: [],
      searchQuery: "",
      submited: false,
      admitSpan: false,
      title: "Filière",
      items: [
        {
          text: "Filières",
          href: "/",
        },
        {
          text: "Modifier titulaire",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      annee_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
        maxLength: helpers.withMessage("1 characteres maximum", maxLength(1)),
      },
      nom_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le nom de la filiere",
          required
        ),
        minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
        maxLength: helpers.withMessage("50 characteres maximum", maxLength(50)),
      },
      niveau_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
      },
      code_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le code de la filiere",
          required
        ),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("7 characteres maximum", maxLength(7)),
      },
      montant_ecolage: {
        required: helpers.withMessage(
          "Veuillez saisir le montant de l'ecolage (FCFA)",
          required
        ),
        minLength: helpers.withMessage("5 characteres minimum", minLength(5)),
        maxLength: helpers.withMessage("8 characteres maximum", maxLength(8)),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
  },
  mounted() {
    this.decryptFiliere();
    this.getFacultyStudents();
    this.getAllStudents();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.studentsList);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.student?.nom.toLowerCase().includes(search) ||
            data.student?.prenom.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    initFacultyCreation() {
      this.submited = true;
      this.updateFiliere();
    },
    leftFilterQuery(event) {
      const leftSearch = event.target.value;
      this.allStudentsBackup = this.allStudents;

      if (leftSearch) {
        const search = leftSearch.trim().toLowerCase();
        this.allStudents = this.allStudentsBackup.filter((student) => {
          const nom = student.nom ? student.nom.toLowerCase() : "";
          const prenom = student.prenom ? student.prenom.toLowerCase() : "";
          return nom.includes(search) || prenom.includes(search);
        });
      } else {
        this.allStudents = this.allStudentsBackup;
      }
    },
    getFacultyStudents() {
      const self = this;
      const faculty = this.data.id;
      this.$store
        .dispatch("getRequest", {
          route: `api/inscrits/${faculty}}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.students = response.data.students;
            self.studentsList = response.data.studentsList;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    getAllStudents() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/etudiant ",
          data: this.data,
        })
        .then(
          function (response) {
            self.allStudents = response.data.searchedUsers;
            self.fetchedStudents = response.data.searchedUsers;
          },
          function (error) {
            console.log(error);
          }
        );
    },
    admitStudents() {
      const self = this;
      this.admitSpan = true;
      (this.admitData = {
        students: this.selectedStudents,
        faculty: this.data.id,
        statut: "actif",
        annee_scolaire: localStorage.getItem("selectedYear"),
      }),
        this.$store
          .dispatch("postRequest", {
            route: "/api/inscrir",
            data: this.admitData,
          })
          .then(
            function (response) {
              Swal.fire(
                response.data.message,
                "Etudiants Inscrits!",
                "success"
              );
              self.getFacultyStudents();
              self.getAllStudents();
              self.loadEnrolledStudenst();
              self.admitSpan = false;
            },
            function (error) {
              console.log(error);
              Swal.fire({
                title: "Oops...",
                text: "Il y a eu une erreur!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
              self.admitSpan = false;
            }
          );
    },
    getTeachers() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/professeur ",
          data: this.data,
        })
        .then(
          function (response) {
            response.data.searchedUsers.forEach((element) => {
              self.teachers.push({
                value: element.id,
                label: element.nom + " " + element.prenom,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("fr-FR", options);
    },
    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      const encryptionKey1 = "Slim#9065";
      const decrypted1 = CryptoJS.AES.decrypt(
        encryptedMessage1,
        encryptionKey1
      );
      const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);
      this.data = JSON.parse(decryptedMessage1);
      this.classTeacher =
        this.data?.department_professors_history[0]?.professeur?.id;
    },
    removeFromAllstudentsAddToSelectedStudents(matricule) {
      const student = this.allStudents.find(
        (student) => student.matricule === matricule
      );
      if (student) {
        this.selectedStudents.push(student);
        this.allStudents = this.allStudents.filter(
          (student) => student.matricule !== matricule
        );
      }
    },
    removeFromSelectedStudentsAddToAllStudents(matricule) {
      const student = this.selectedStudents.find(
        (student) => student.matricule === matricule
      );
      if (student) {
        this.allStudents.push(student);
        this.selectedStudents = this.selectedStudents.filter(
          (student) => student.matricule !== matricule
        );
      }
    },
    removeAllStudentsFromStudents() {
      this.selectedStudents = [...this.selectedStudents, ...this.allStudents];
      this.allStudents = [];
    },

    removeAllStudentsFromSelectedStudents() {
      this.allStudents = [...this.allStudents, ...this.selectedStudents];
      this.selectedStudents = [];
    },
    loadEnrolledStudenst() {
      this.selectedStudents = [...this.students];
      const fetchedStudents = [...this.fetchedStudents];
      this.allStudents = fetchedStudents.filter(
        (student) =>
          !this.selectedStudents.some(
            (selected) => selected.matricule === student.matricule
          )
      );
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.studentsList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    getStudent(student) {
      this.student = student;
    },
    async exclude(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous exclure cet élève de cette classe ? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value == 1) {
          this.$store
            .dispatch("putRequest", {
              route: `api/inscrir/${id}`,
              data: { department_id: this.data.id },
            })
            .then(
              function (response) {
                self.getFacultyStudents();
                self.getAllStudents();
                console.log(response);
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },
  },
};
</script>

<style scoped>
.multiselect-listbox {
  display: flex;
  gap: 1rem;
}

.listbox,
.selected-listbox {
  padding: 1rem;
  width: 100%;
  height: 350px;
}

.listbox .list__items,
.selected-listbox .list__items {
  border: 1px solid #ccc;
  margin-top: 13px;
  height: 100%;
  margin-left: 0rem;
  flex-basis: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.listbox .list__items .list__item,
.selected-listbox .list__items .list__item {
  font-size: 0.9em;
  padding: 7px 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  list-style: none;
  cursor: pointer;
}

.list__item:hover {
  background-color: #ccc;
}

.buttons {
  cursor: pointer;
  color: #3eaf7c;
  font-weight: 500;
  text-decoration: none;
}

.list__actions i {
  font-size: x-large;
  font-weight: 900;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input
                type="text"
                v-model="searchQuery"
                class="form-control"
                placeholder="Search..."
              />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                @click="loadEnrolledStudenst"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Inscrire
              </button>
            </div>
          </div>
          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="currency_name" scope="col">
                    Matricule
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Nom
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Prenom
                  </th>
                  <th class="sort" data-sort="high" scope="col">Sexe</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td class="id">{{ data.student?.matricule }}</td>
                  <td>{{ data.student?.nom }}</td>
                  <td>{{ data.student?.prenom }}</td>
                  <td>{{ data.student?.sexe?.toUpperCase() }}</td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalgrid"
                        @click="getStudent(data.enrollement_slices)"
                        class="ml-3 link-success fs-15"
                        ><i class="las la-hand-holding-usd"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="exclude(data.student?.id)"
                        class="link-danger fs-15"
                        ><i class="las la-user-times"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div
            class="modal fade"
            id="exampleModalgrid"
            tabindex="-1"
            aria-labelledby="exampleModalgridLabel"
            aria-modal="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body card">
                  <div class="card-body">
                    <div class="table-responsive table-card">
                      <table
                        class="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead class="table-light text-muted">
                          <tr>
                            <th
                              class="sort"
                              data-sort="currency_name"
                              scope="col"
                            >
                              Tranche
                            </th>
                            <th
                              class="sort"
                              data-sort="current_value"
                              scope="col"
                            >
                              Montant
                            </th>
                            <th class="sort" data-sort="high" scope="col">
                              Date d'échéance
                            </th>
                            <th scope="col">Etat</th>
                          </tr>
                        </thead>
                        <!--end thead-->
                        <tbody class="list form-check-all">
                          <tr v-for="(tranche, index) of student" :key="index">
                            <td class="text-bold">{{ tranche.slice?.name }}</td>
                            <td>{{ tranche.amount }} Frcfa</td>
                            <td>{{ formatDate(tranche.account_date) }}</td>
                            <td
                              :class="
                                tranche.status === 0
                                  ? 'text-danger text-bold'
                                  : 'text-success text-bold'
                              "
                            >
                              {{ tranche.status === 0 ? "Non Payé" : "Payé" }}
                            </td>
                          </tr>
                        </tbody>
                        <!--end tbody-->
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-5">
                      <div class="listbox">
                        <input
                          type="text"
                          class="form-control"
                          @input="leftFilterQuery"
                          placeholder="Search..."
                        />
                        <div class="list__items">
                          <div
                            class="list__item"
                            v-for="student in allStudents"
                            :key="student.matricule"
                            @click="
                              removeFromAllstudentsAddToSelectedStudents(
                                student.matricule
                              )
                            "
                          >
                            {{ student.nom }} {{ student.prenom }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-2 d-flex flex-column m-auto justify-content-center align-items-center list__actions"
                      style="height: 150px"
                    >
                      <a
                        href="#"
                        class="buttons mb-4"
                        @click="removeAllStudentsFromStudents()"
                      >
                        <i class="las la-angle-double-right"></i>
                      </a>
                      <li class="las la-exchange-alt"></li>
                      <a
                        href="#"
                        class="buttons mt-4"
                        @click="removeAllStudentsFromSelectedStudents()"
                      >
                        <i class="las la-angle-double-left"></i>
                      </a>
                    </div>
                    <div class="col-lg-5">
                      <div class="selected-listbox">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                        />
                        <div class="list__items">
                          <div
                            class="list__item"
                            v-for="student in selectedStudents"
                            :key="student.matricule"
                            @click="
                              removeFromSelectedStudentsAddToAllStudents(
                                student.matricule
                              )
                            "
                          >
                            {{ student.nom }} {{ student.prenom }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <a
                  href="javascript:void(0);"
                  class="btn btn-link link-success fw-medium"
                  data-bs-dismiss="modal"
                >
                  <i class="ri-close-line me-1 align-middle"></i> Close
                </a>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="admitSpan"
                  @click="admitStudents"
                >
                  <span
                    v-if="admitSpan"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
